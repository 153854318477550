import router from '@/router'
import store from '@/store'
import { saveFile } from '@/services/util'

const headersIncludingAuth = () => {
  const token = store.getters['account/token']
  const lang = store.getters['locale/lang']
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, Accept',
  }
  if (token) {
    headers['Authentication-Token'] = token
  }
  if (lang) {
    headers['Accept-Language'] = lang
  }
  return headers
}

class NotFoundError extends Error {
  constructor(data) {
    super(data)
    this.name = 'NotFoundError'
    this.message = 'Not found'
    this.data = data
  }
}

function BadRequestError(invalidFields) {
  this.invalidFields = invalidFields
  this.name = 'BadRequestError'
  this.message = 'Bad request'
}

function UnprocessableEntityError(invalidFields) {
  this.invalidFields = invalidFields
  this.name = 'UnprocessableEntityError'
  this.message = 'Unprocessable request'
}

const logout = () => {
  store.reset()
  localStorage.removeItem('user')
}

const login = async (email, password) => {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Accept-Language': store.getters['locale/lang'],
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  }
  const response = await fetch(`${process.env.VUE_APP_API_URL}/accounts/login?include_auth_token&include_user`, options)
  if (!response) {
    throw new Error('Network response was not ok.')
  }
  const data = await response.json()
  if (response.ok) {
    // store user details and token in local storage to keep user logged in between visits
    localStorage.setItem('user', JSON.stringify(data.response.user))
    return data.response.user
  } else if (response.status === 401 || response.status === 403) {
    logout()
    router.push({ name: 'login' })
  } else if (response.statusText) {
    throw new Error(response.statusText)
  } else {
    throw new Error('Network response was not ok.')
  }
}

const parseContentDisposition = (headerValue) => {
  const array = headerValue.split(';').map((s) => s.trim())
  const filenameMatches = /filename="([^"]*)"/.exec(array[1])
  const filename = filenameMatches && filenameMatches.length > 1 ? filenameMatches[1] : null
  return {
    isAttachment: array[0] === 'attachment',
    filename,
  }
}

const parseContentType = (headerValue) => {
  const array = headerValue.split(';').map((s) => s.trim())
  const charsetMatches = /charset=(.*)"/.exec(array[1])
  const charset = charsetMatches && charsetMatches.length > 1 ? charsetMatches[1] : null
  return {
    mimeType: array[0],
    charset,
  }
}

const call = async (url, method, body) => callInternal(url, method, body, (response) => response.json())

const fetchFile = async (url, method, body) =>
  callInternal(url, method, body, async (response) => {
    if (response.headers.has('Content-Disposition') && response.headers.has('Content-Type')) {
      const contentDisposition = parseContentDisposition(response.headers.get('Content-Disposition'))
      if (contentDisposition.isAttachment) {
        const contentType = parseContentType(response.headers.get('Content-Type'))
        const data = await response.text()
        saveFile(data, contentDisposition.filename, contentType.mimeType)
      }
    }
    return null
  })

const callInternal = async (url, method, body, handler) => {
  let options = {
    headers: headersIncludingAuth(),
  }
  if (method === undefined) {
    options.method = 'GET'
  } else {
    options.method = method
  }
  if (body !== undefined) {
    options.body = JSON.stringify(body)
  }
  const response = await fetch(`${process.env.VUE_APP_API_URL}${url}`, options)
  if (!response) {
    throw new Error('Network response was not ok.')
  }
  if (response.ok) {
    return handler(response)
  }
  const data = await response.json()
  if (response.status === 401 || response.status === 403) {
    logout()
    router.push({ name: 'login' })
  } else if (response.status === 404) {
    throw new NotFoundError(data)
  } else if (response.status === 400) {
    throw new BadRequestError(data.errors)
  } else if (response.status === 422) {
    throw new UnprocessableEntityError(data.errors.json)
  } else if (response.statusText) {
    throw new Error(response.statusText)
  } else {
    throw new Error('Network response was not ok.')
  }
}

const api = {
  call,
  fetchFile,
  login,
  logout,
  BadRequestError,
  UnprocessableEntityError,
}

export default api
