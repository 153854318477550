// Store for UI settings and preferences

const defaultPortfolioMapZoom = 6.5
const defaultPortfolioMapCenter = { lng: 8.15, lat: 46.85 }

// Variables saved in local storage:
const state = {
  currentPortfolioId: JSON.parse(localStorage.getItem('currentPortfolioId')) || null,
  portfolioMapAndKpisMode: 'map', // 'map' or 'kpis'
  portfolioKpiOverviewMode: 'count', // 'count' or 'area'
  portfolioListDisplayMode: 'cards', // 'cards' or 'table'
  portfolioMapZoom: defaultPortfolioMapZoom,
  portfolioMapCenter: defaultPortfolioMapCenter,
  buildingMapAndKpisMode: 'map', // 'map' or 'bubble'
  selectedTabIndices: JSON.parse(localStorage.getItem('selectedTabIndices')) || {},
}

// Getters
const getters = {
  getCurrentPortfolioId(state) {
    return () => {
      return state.currentPortfolioId
    }
  },
  getPortfolioMapAndKpisMode(state) {
    return () => {
      return state.portfolioMapAndKpisMode
    }
  },
  getPortfolioKpiOverviewMode(state) {
    return () => {
      return state.portfolioKpiOverviewMode
    }
  },
  getPortfolioListDisplayMode(state) {
    return () => {
      return state.portfolioListDisplayMode
    }
  },
  getPortfolioMapZoom(state) {
    return () => {
      return state.portfolioMapZoom
    }
  },
  getDefaultPortfolioMapZoom() {
    return () => {
      return defaultPortfolioMapZoom
    }
  },
  getPortfolioMapCenter(state) {
    return () => {
      return state.portfolioMapCenter
    }
  },
  getDefaultPortfolioMapCenter() {
    return () => {
      return defaultPortfolioMapCenter
    }
  },
  getBuildingMapAndKpisMode(state) {
    return () => {
      return state.buildingMapAndKpisMode
    }
  },
  getSelectedTabIndex(state) {
    return (group) => {
      return state.selectedTabIndices[group] || 0
    }
  },
}

// Actions
const actions = {
  setCurrentPortfolioId({ commit }, portfolioId) {
    commit('SET_CURRENT_PORTFOLIO_ID', portfolioId)
  },
  setPortfolioMapAndKpisMode({ commit }, mode) {
    commit('SET_PORTFOLIO_MAP_AND_KPIS_MODE', mode)
  },
  setPortfolioKpiOverviewMode({ commit }, mode) {
    commit('SET_PORTFOLIO_KPI_OVERVIEW_MODE', mode)
  },
  setPortfolioListDisplayMode({ commit }, mode) {
    commit('SET_PORTFOLIO_LIST_DISPLAY_MODE', mode)
  },
  setPortfolioMapZoom({ commit }, zoom) {
    commit('SET_PORTFOLIO_MAP_ZOOM', zoom)
  },
  resetPortfolioMapZoom({ commit }) {
    commit('SET_PORTFOLIO_MAP_ZOOM', defaultPortfolioMapZoom)
  },
  setPortfolioMapCenter({ commit }, center) {
    commit('SET_PORTFOLIO_MAP_CENTER', center)
  },
  resetPortfolioMapCenter({ commit }) {
    commit('SET_PORTFOLIO_MAP_CENTER', defaultPortfolioMapCenter)
  },
  setBuildingMapAndKpisMode({ commit }, mode) {
    commit('SET_BUILDING_MAP_AND_KPIS_MODE', mode)
  },
  setSelectedTabIndex({ commit }, { group, index }) {
    commit('SET_SELECTED_TAB_INDEX', { group, index })
  },
}

// Mutations
const mutations = {
  SET_CURRENT_PORTFOLIO_ID(state, portfolioId) {
    state.currentPortfolioId = portfolioId
    localStorage.setItem('currentPortfolioId', portfolioId)
  },
  SET_PORTFOLIO_MAP_AND_KPIS_MODE(state, mode) {
    state.portfolioMapAndKpisMode = mode
    localStorage.setItem('portfolioMapAndKpisMode', mode)
  },
  SET_PORTFOLIO_KPI_OVERVIEW_MODE(state, mode) {
    state.portfolioKpiOverviewMode = mode
    localStorage.setItem('portfolioKpiOverviewMode', mode)
  },
  SET_PORTFOLIO_LIST_DISPLAY_MODE(state, mode) {
    state.portfolioListDisplayMode = mode
    localStorage.setItem('portfolioListDisplayMode', mode)
  },
  SET_PORTFOLIO_MAP_ZOOM(state, zoom) {
    if (state.portfolioMapZoom === zoom) {
      return
    }
    state.portfolioMapZoom = zoom
  },
  SET_PORTFOLIO_MAP_CENTER(state, center) {
    if (state.portfolioMapCenter.lon === center.lon && state.portfolioMapCenter.lat === center.lat) {
      return
    }
    state.portfolioMapCenter = center
  },
  SET_BUILDING_MAP_AND_KPIS_MODE(state, mode) {
    state.buildingMapAndKpisMode = mode
    localStorage.setItem('buildingMapAndKpisMode', mode)
  },
  SET_SELECTED_TAB_INDEX(state, { group, index }) {
    // Brute force rewrite of the whole object to trigger reactivity
    // Otherwise, the computed property in the component won't be updated (nested objects are not reactive)
    state.selectedTabIndices = { ...state.selectedTabIndices, [group]: index }
    localStorage.setItem('selectedTabIndices', JSON.stringify(state.selectedTabIndices))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
