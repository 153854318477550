import Building from '@/models/Building'

// snake case for backwards compatibility
export default class Portfolio {
  constructor(portfolio) {
    this.summary = portfolio.summary
    this.id = portfolio.id
    this.inactive_after = portfolio.inactive_after
    this.name = portfolio.name

    // details
    this.buildings = []
    if (portfolio.buildings !== undefined) {
      this.buildings = portfolio.buildings.map((b) => new Building(b))
    }
    this.enabled_features = portfolio.enabled_features
    this.energy_carriers = portfolio.energy_carriers
    this.heating_types = portfolio.heating_types
    this.energy_prices = portfolio.energy_prices
    this.heat_grids = portfolio.heat_grids
    this.interest_rate = portfolio.interest_rate
    this.investment_costs = portfolio.investment_costs
    this.last_modified = portfolio.last_modified
    this.lifespans = portfolio.lifespans
    this.operation_costs = portfolio.operation_costs
    this.permissions = portfolio.permissions
    this.renovation_standards = portfolio.renovation_standards
    this.scenarios = portfolio.scenarios
    this.sim_end_year = portfolio.sim_end_year
    this.sim_start_year = portfolio.sim_start_year
    this.subsidies = portfolio.subsidies
    this.tag_groups = portfolio.tag_groups
    this.targets = portfolio.targets
    this.usage_types_standard = portfolio.usage_types_standard

    // vuex state
    Object.defineProperty(this, 'users', { writable: true }) // transient, ignore in JSON
    Object.defineProperty(this, '__buildings_loading', { writable: true }) // transient, ignore in JSON
    Object.defineProperty(this, '__buildings_loaded', { writable: true }) // transient, ignore in JSON
    Object.defineProperty(this, '__details_loading', { writable: true }) // transient, ignore in JSON
    Object.defineProperty(this, '__details_loaded', { writable: true }) // transient, ignore in JSON
    Object.defineProperty(this, '__users_loading', { writable: true }) // transient, ignore in JSON
    Object.defineProperty(this, '__users_loaded', { writable: true }) // transient, ignore in JSON
    this.users = []
    this.__buildings_loading = portfolio.__buildings_loading
    this.__buildings_loaded = portfolio.__buildings_loaded
    this.__details_loading = portfolio.__details_loading
    this.__details_loaded = portfolio.__details_loaded
    this.__users_loading = portfolio.__users_loading
    this.__users_loaded = portfolio.__users_loaded
  }

  getEnergyCarrierName(i18n, index) {
    if (index >= 0 && this.energy_carriers.length > index && this.energy_carriers[index].default) {
      return i18n(`_energyCarriers.${this.energy_carriers[index].name}`)
    }
    return index >= 0 ? this.energy_carriers[index].name : i18n(`_energyCarriers.UNKNOWN`)
  }

  getHeatingTypeName(i18n, index) {
    if (index >= 0 && this.heating_types.length > index && this.heating_types[index].default) {
      return i18n(`_heatingTypes.${this.heating_types[index].name}`)
    }
    return index >= 0 ? this.heating_types[index].name : i18n(`_heatingTypes.UNKNOWN`)
  }
}
