import Vue from 'vue'

import compassApi from '@/services/compass-api'

const state = {
  loading: true,
  loaded: false,
  portfolioId: null,
  portfolioBuildingTags: [], // List of portfolio building tags
  // Structure of a portfolio building tag:
  // {
  //   id
  //   name
  //   auto
  //   tags: []
  //   total_available
  // }
}

// Getters
const getters = {
  getPortfolioBuildingTags(state) {
    return () => {
      return state.portfolioBuildingTags
    }
  },
}

// Actions
const actions = {
  // Load portfolio building tags
  async loadPortfolioBuildingTagsByPortfolioId({ commit, rootState }, { portfolioId, reset }) {
    commit('portfolioBuildingTagsLoading')
    if (portfolioId) {
      if (state.portfolioId !== portfolioId) {
        commit('reset')
      }
      commit('setContext', { portfolioId })
      const query = JSON.stringify(rootState.query)
      const portfolioBuildingTags = await compassApi.call(`/portfolios/${portfolioId}/building-tags?query=${query}`)
      commit('portfolioBuildingTagsLoaded', { portfolioBuildingTags })
    }
  },

  async refresh({ dispatch, state }) {
    if (state.portfolioId) {
      dispatch('loadPortfolioBuildingTagsByPortfolioId', { portfolioId: state.portfolioId })
    }
  },
}

// Mutations
const mutations = {
  setContext(state, { portfolioId }) {
    Vue.set(state, 'portfolioId', portfolioId)
  },

  reset(state) {
    Vue.set(state, 'portfolioBuildingTags', [])
  },

  // When portfolio building tags are being loaded
  portfolioBuildingTagsLoading(state) {
    Vue.set(state, 'loaded', false)
    Vue.set(state, 'loading', true)
  },

  // When portfolio building tags have been loaded
  portfolioBuildingTagsLoaded(state, { portfolioBuildingTags }) {
    Vue.set(state, 'portfolioBuildingTags', portfolioBuildingTags)
    Vue.set(state, 'loading', false)
    Vue.set(state, 'loaded', true)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
