import Vue from 'vue'
import { genericLoadWithPortfolioAndQuery, genericRefreshWithPortfolioAndQuery } from '@/store/storeUtil'

const state = {
  loading: true,
  loaded: false,
  buildingsForList: [],
  // Context
  latestRequestId: null,
  portfolioId: null,
  query: '{}',
}

// Getters
const getters = {}

// Actions
const actions = {
  async load({ commit, rootState, state }, { portfolioId }) {
    await genericLoadWithPortfolioAndQuery({
      commit,
      state,
      rootState,
      portfolioId,
      urlFct: (portfolioId, query) => `/portfolios/${portfolioId}/buildings-for-list?query=${query}`,
    })
  },

  async refresh({ commit, rootState, state }) {
    await genericRefreshWithPortfolioAndQuery({
      commit,
      state,
      rootState,
      urlFct: (portfolioId, query) => `/portfolios/${portfolioId}/buildings-for-list?query=${query}`,
    })
  },
}

// Mutations
const mutations = {
  setContext(state, { portfolioId, query, requestId }) {
    Vue.set(state, 'portfolioId', portfolioId)
    Vue.set(state, 'query', query)
    Vue.set(state, 'latestRequestId', requestId)
  },

  reset(state) {
    Vue.set(state, 'buildingsForList', [])
  },

  loading(state) {
    Vue.set(state, 'loaded', false)
    Vue.set(state, 'loading', true)
  },

  loaded(state, { data }) {
    Vue.set(state, 'buildingsForList', data)
    Vue.set(state, 'loaded', true)
    Vue.set(state, 'loading', false)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
