// snake case for backwards compatibility
export default class Building {
  constructor(building) {
    this.basement_replacement_year = building.basement_replacement_year
    this.basement_year = building.basement_year
    this.building_year = building.building_year
    this.cadastre_state = building.cadastre_state
    this.data_quality = building.data_quality
    this.description = building.description
    this.dhw_calibration = building.dhw_calibration
    this.dhw_demand = building.dhw_demand
    this.dhw_end_energy = building.dhw_end_energy
    this.ee = building.ee
    this.ee_by_carrier = building.ee_by_carrier
    this.ee_by_usage = building.ee_by_usage
    this.el_demand = building.el_demand
    this.el_general = building.el_general
    this.el_ownuse = building.el_ownuse
    this.el_production = building.el_production
    this.el_tenants = building.el_tenants
    this.energy_area = building.energy_area
    this.energy_grids_state = building.energy_grids_state
    this.flags = building.flags
    this.geothermal_state = building.geothermal_state
    this.s12e = building.s12e
    this.s12e_by_carrier = building.s12e_by_carrier
    this.s12e_by_usage = building.s12e_by_usage
    this.s1e = building.ghg_s1
    this.s1e_by_carrier = building.s1e_by_carrier
    this.s1e_by_usage = building.s1e_by_usage
    this.status = building.status
    this.status_change_pending = building.status_change_pending
    this.gwr_state = building.gwr_state
    this.heat_demand = building.heat_demand
    this.heat_end_energy = building.heat_end_energy
    this.heating = building.heating
    this.heating_power = building.heating_power
    this.heating_replacement_year = building.heating_replacement_year
    this.heating_type = building.heating_type
    this.heating_year = building.heating_year
    this.id = building.id
    this.identifier = building.identifier
    this.kpis = building.kpis
    this.latitude = building.latitude
    this.longitude = building.longitude
    this.main_usage = building.main_usage
    this.meters = building.meters
    this.municipality = building.municipality
    this.pe = building.pe
    this.pe_by_carrier = building.pe_by_carrier
    this.pe_by_usage = building.pe_by_usage
    this.pe_ratio = building.pe_ratio
    this.pe_ref = building.pe_ref
    this.portfolio_id = building.portfolio_id
    this.pv_power = building.pv_power
    this.rental_area = building.rental_area
    this.rh_calibration = building.rh_calibration
    this.rh_demand = building.rh_demand
    this.rh_end_energy = building.rh_end_energy
    this.rh_limit = building.rh_limit
    this.rh_ratio = building.rh_ratio
    this.roof_replacement_year = building.roof_replacement_year
    this.roof_state = building.roof_state
    this.roof_year = building.roof_year
    this.tags = building.tags
    this.walls_replacement_year = building.walls_replacement_year
    this.walls_year = building.walls_year
    this.windows_replacement_year = building.windows_replacement_year
    this.windows_year = building.windows_year
    this.zip_code = building.zip_code
    this.zones = building.zones

    // details
    this.active_from = building.active_from
    this.active_to = building.active_to
    this.addresses = building.addresses
    this.bbox = building.bbox
    this.cadastre = building.cadastre
    this.egids = building.egids
    this.el_utility = building.el_utility
    this.energy_grids = building.energy_grids
    this.feasibility = building.feasibility
    this.geothermal = building.geothermal
    this.gwr = building.gwr
    this.initial_state = building.initial_state
    this.kpi = building.kpi
    this.location_info = building.location_info
    this.measures = building.measures
    this.perimeter = building.perimeter
    this.permissions = building.permissions
    this.uiPermissions = building.uiPermissions
    this.remarks = building.remarks
    this.renovation_standards = building.renovation_standards
    this.roof = building.roof
    this.tags = building.tags

    // vuex state
    Object.defineProperty(this, '__loading', { writable: true }) // transient, ignore in JSON
    Object.defineProperty(this, '__loaded', { writable: true }) // transient, ignore in JSON
    this.__loading = building.__loading
    this.__loaded = building.__loaded
  }
}

export const BUILDING_STATUS = {
  PLANNER: 'PLANNER',
  REPORTER: 'REPORTER',
  ARCHIVED: 'ARCHIVED',
}

export const BUILDING_STATUS_CHANGE_PENDING = {
  REPORTER: 'PENDING_REPORTER',
  ARCHIVED: 'PENDING_ARCHIVED',
}
