import Vue from 'vue'
import WebFont from 'webfontloader'

import { Chart, registerables } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'

import Sticky from 'vue-sticky-directive'
import VueHead from 'vue-head' // use https://github.com/unjs/unhead instead with vue3
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import i18n from '@/plugins/i18n'

import App from '@/App'
import router from '@/router'
import store from '@/store'
import VueLuxon from 'vue-luxon'

Vue.config.productionTip = false
Vue.config.devtools = true

i18n.locale = store.state.locale.current

WebFont.load({
  google: {
    families: ['Source Sans Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i'],
  },
})

// Overwrite default chart styling
Chart.register(...registerables, annotationPlugin, {
  id: 'canvasColor',
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = options.color || 'transparent'
    ctx.fillRect(0, 0, chart.width, chart.height)
    ctx.restore()
  },
})
Chart.defaults.font = {
  ...Chart.defaults.font,
  family: 'Source Sans Pro',
  size: 14,
}
Chart.defaults.plugins.tooltip = {
  ...Chart.defaults.plugins.tooltip,
  titleSpacing: 10,
  bodySpacing: 10,
  titleFontSize: 14,
  titleMarginBottom: 10,
  padding: 12,
  xPadding: 12,
  yPadding: 12,
  boxPadding: 5,
  cornerRadius: 5,
}

Vue.component('v-select', vSelect)
Vue.use(VueLuxon)
Vue.use(Sticky)
Vue.use(VueHead, {
  separator: '-',
})

Vue.directive('matchText', (el, binding) => {
  const text = el.textContent
  const searchText = binding.value
  if (searchText) {
    const match = text.toLowerCase().match(searchText)
    if (match) {
      el.innerHTML = `<em class="match">${text}</em>`
      return
    }
  }
  el.innerHTML = text
})

Vue.mixin({
  methods: {
    /*
     * Precision: Number of digits after the point (negative numbers round to 10/100/...)
     */
    formatNumber: (x, precision) => {
      if (x === undefined || x === null) {
        return x
      }
      if (precision !== undefined) {
        x = Math.round(x * Math.pow(10, precision)) / Math.pow(10, precision)
      }
      x = x.toString().split('.')
      if (x.length > 1) {
        return x[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") + '.' + x[1].toString()
      } else {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
      }
    },

    formatString: (str) => {
      if (!str) {
        return '-'
      }
      return str
    },
  },
})

Vue.directive('focus', (el) => {
  el.focus()
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
