<template>
  <div id="app">
    <keep-alive include="portfolio-page">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import PortfolioBuildingEventMixin from '@/pages/vuex-mixins/PortfolioBuildingEventMixin.vue'
import PortfolioBuildingSimulationQueueMixin from '@/pages/vuex-mixins/PortfolioBuildingSimulationQueueMixin.vue'

export default {
  mixins: [
    //
    PortfolioBuildingSimulationQueueMixin,
    PortfolioBuildingEventMixin,
  ],
}
</script>

<style lang="scss">
/*
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}
span {
  display: inline-block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
sub,
sup {
  font-size: 0.5em;
}
sub {
  vertical-align: sub;
}
sup {
  vertical-align: super;
}
strong {
  font-weight: 700;
}

/* 
 Compass resets
 */

html {
  box-sizing: border-box;
  position: relative;
  height: 100%;
}

/* Hide scrollbar, but allow scrolling */
html,
body {
  -ms-overflow-style: none !important; /* for Internet Explorer, Edge */
  scrollbar-width: none !important; /* for Firefox */
  overflow-y: scroll !important;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none !important; /* for Chrome, Safari, and Opera */
}

/* Body */
body {
  height: 100% !important;
  min-height: 100svh !important;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.5;
}

/* Reset */
*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: var(--font-family);
  font-size: 16px;
}

img,
picture,
video,
canvas,
svg {
  display: inline-block;
  max-width: 100%;
}

/* 
 Compass variables
 */

:root {
  /* remove rem: search for `rem;`, `rem)`, or `rem ` */

  --font-xl: 40px;
  --font-l: 32px;
  --font-m: 24px;
  --font-s: 16px;
  --font-xs: 13px;

  /* --font-xl: 3.375em;
  --font-l: 1.85em;
  --font-m: 1.3em;
  --font-s: 1em;
  --font-xs: 0.8em; */

  --font-family: 'Source Sans Pro', 'Lucida Sans', 'Lucida Grande', 'Lucida Sans Unicode', 'Open Sans', 'Droid Sans',
    sans-serif;

  --sticky-header-height: 65px;

  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  font-feature-settings: 'kern', 'liga', 'clig', 'calt', 'onum', 'pnum';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --link-color: hsl(0, 0%, 13%, 0.9);
  --link-color-hover: hsl(0, 0%, 13%);

  /* Old blue: hsl or hsla 202, 92% */

  --text-color: hsl(0, 0%, 13%);
  --secondary-text-color: hsla(0, 0%, 13%, 0.6);
  --inverted-text-color: #ffffff;
  --highlight-color: hsl(0, 0%, 13%);
  --secondary-highlight-color: hsla(0, 0%, 13%);
  --background-color: #ffffff;
  --secondary-background-color: hsla(202, 80%, 90%);
  --translucent-background-color: hsla(0, 0%, 0%, 0.6);
  --shadow-color: hsla(0, 0%, 13%, 0.3);
  --separator-color: #dddddd;

  --success-color: #76cd99;
  --error-color: #e7595a;
  --warning-color: #fb805d;

  --ranking-1-color: #c65051;
  --ranking-2-color: #fb805d;
  --ranking-3-color: #ef9852;
  --ranking-4-color: #f1c757;
  --ranking-5-color: #5fccf0;

  --track-color: #e0e0e0;

  --popover-background-color: #333333;
  --popover-text-color: #ffffff;
  --popover-hairline-color: #444444;

  --spacing-xxl: 40px;
  --spacing-xl: 32px;
  --spacing-l: 24px;
  --spacing-m: 16px;
  --spacing-s: 10px;
  --spacing-xs: 6px;
  --spacing-xxs: 4px;

  /* --spacing-xxl: 4.632rem;
  --spacing-xl: 2.779rem;
  --spacing-l: 1.667rem;
  --spacing-m: 1rem;
  --spacing-s: 0.6rem;
  --spacing-xs: 0.36rem;
  --spacing-xxs: 0.216rem; */

  --box-padding-h: var(--spacing-s);
  --box-padding-v: var(--spacing-xs);
  --box-padding: var(--box-padding-v) var(--box-padding-h);
  --box-padding-equal: var(--box-padding-v) var(--box-padding-v);
  --box-border-width: 1px;
  --box-border: var(--box-border-width) solid var(--hairline-color);
  --box-radius: var(--spacing-xs);
  --box-two-column-width: (100% - var(--box-padding-h)) / 2;
  --box-error-border-width: 1px;
  --box-error-border: var(--box-error-border-width) solid var(--warning-color);

  --hairline-width: 1px;
  --hairline-color: #ddd;
  --default-column-width: 14rem;
}

/* App */

#app {
  height: 100%;
}

/* Vselect */

.v-select {
  position: relative;
  min-height: 36px;
  min-width: 0;
  width: 100%;

  & .vs__dropdown-toggle {
    width: 100% !important;
    min-height: 36px !important;
    padding: 0 !important;

    &:hover {
      border-color: #888;
    }
  }

  & .vs__selected-options {
    width: 100%;
    flex-wrap: nowrap;
  }

  & .vs__selected {
    position: relative !important;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 20px 0 0;
    width: 100%;
  }

  & .vs__actions {
    position: absolute;
    top: 0;
    right: 3px;
    transform: translateY(50%);
    z-index: 1;
    height: 16px;
    cursor: pointer;
  }

  & .vs__search {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &::placeholder {
      color: #888;
    }
  }

  /* Text can be blurry depending on width of window: text gets rendered at floating point pixel values, causing blurry rendering */
  & .vs__dropdown-menu {
    border-radius: 4px;
    padding: 0;
    z-index: 12;

    & .vs__dropdown-option {
      // Adding top and/or bottom padding will produce blurry effect
      padding-left: var(--spacing-s);
      padding-right: var(--spacing-s);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover,
      &.vs__dropdown-option--selected,
      &.vs__dropdown-option--highlight {
        background-color: #333;
        color: #fff;
        font-weight: 500;
      }
    }
  }
}

p ~ p {
  margin: 0.75em 0 0;
}

a,
a:visited {
  color: var(--link-color);
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: var(--link-color-hover);
  }
}

abbr {
  letter-spacing: 0.01em;
  font-feature-settings: 'kern', 'liga', 'clig', 'calt', 'c2sc', 'smcp';
}

@supports (font-variant-caps: all-small-caps) {
  abbr {
    font-variant-caps: all-small-caps;
    font-feature-settings: normal;
  }
}

.numbers {
  letter-spacing: 0.01em;
}

.match {
  display: inline-block;
  background-color: hsl(60, 80%, 90%);
  border-radius: 0.3em;
  padding: 0 0.3em;
  margin: 0 -0.3em;
}

label {
  -webkit-tap-highlight-color: transparent;
}

input[type='text']:not(.mx-input),
input[type='email'],
input[type='password'],
textarea,
.button {
  font-weight: 400;
  color: var(--text-color);
  font-size: var(--font-s);
  line-height: 1.5;
  margin: 0;
  outline: none;
  appearance: none;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s;
  width: auto;
  scale: 1;
}

input:hover {
  &:not(.vs__search) {
    &:not(.mx-input) {
      border-color: #888;
    }
  }
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  &:not(.mx-input) {
    background-color: var(--background-color);
    border: var(--box-border);
    border-radius: var(--box-radius);
    color: var(--highlight-color);
    font-weight: 400;

    &:focus {
      border-color: #888 !important;
      outline: 3px solid #eee;
    }
  }
}

input[type='text'],
input[type='email'],
input[type='password'] {
  &:not(.mx-input) {
    padding: var(--box-padding);
    width: 100%;
    max-width: 100%;
  }
}

input[type='text']::placeholder,
input[type='email']::placeholder,
input[type='password']::placeholder {
  color: #888;
}

.button {
  cursor: pointer;
  /* display: inline-block; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  /* background-color: black; */
  /* color: white !important; */
  padding: var(--box-padding);
  /* padding: 6px 16px; */
  border: var(--box-border);
  border-radius: var(--box-radius);
  /* border-radius: 24px; */
  /* line-height: 1; */
  white-space: nowrap;
  text-align: center;
  -webkit-tap-highlight-color: transparent;

  &.button--square {
    padding: var(--box-padding-equal);
  }

  & .icon {
    opacity: 0.7;
    vertical-align: middle;
    /* vertical-align: text-top; */
  }

  &:hover {
    border-color: #aaa;

    & .icon {
      opacity: 0.9;
    }
  }

  &.active {
    border-color: var(--secondary-background-color);
    background-color: var(--secondary-background-color);

    & .icon {
      opacity: 0.6;
    }
  }

  &:disabled {
    color: #aaaaaa;
  }
}

* + .button {
  margin-left: var(--spacing-s);
}

.input_false {
  background-image: none !important;
  background-color: #f2dede !important;
  box-shadow: 0 0 0px 1000px #f2dede inset !important;
}

table {
  &:not(.mx-table) {
    border-collapse: collapse;
  }
}

input[type='checkbox'],
input[type='radio'] {
  /* Larger checkboxes */
  -ms-transform: scale(1.33); /* IE */
  -moz-transform: scale(1.33); /* FF */
  -webkit-transform: scale(1.33); /* Safari and Chrome */
  -o-transform: scale(1.33); /* Opera */
  transform: scale(1.33);
  margin: var(--spacing-s);
}

@media screen and (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0 !important;
    transition-duration: 0 !important;
  }
}

.sticky-header .compass-header .secondary-nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 1px var(--shadow-color);
}

.sticky-header .compass-header .primary-nav {
  margin-bottom: var(--sticky-header-height);
}

@import url('https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css');

h1 {
  font-size: var(--font-l);
  font-weight: 900;
  color: var(--secondary-highlight-color);
  margin-bottom: var(--spacing-m);
}

h2 {
  font-size: var(--font-m);
  font-weight: 700;
  color: var(--secondary-highlight-color);
  margin-bottom: var(--spacing-s);
}

h3 {
  font-size: var(--font-s);
  font-weight: 700;
  color: var(--secondary-highlight-color);
  margin-bottom: var(--spacing-s);
  margin-top: var(--spacing-l);
}

h4 {
  font-weight: 400;
  color: var(--secondary-highlight-color);
  margin-bottom: var(--spacing-s);
  margin-top: var(--spacing-s);
}

h5 {
  font-weight: 400;
  color: var(--secondary-highlight-color);
}

/* Tooltip */

.tooltip {
  display: block !important;
  z-index: 13;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: var(--box-radius);
  padding: var(--spacing-s) 0;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 13;
}

.tooltip[x-placement^='top'] {
  margin-bottom: 5px;
}

.tooltip[x-placement^='top'] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^='bottom'] {
  margin-top: 5px;
}

.tooltip[x-placement^='bottom'] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^='right'] {
  margin-left: 5px;
}

.tooltip[x-placement^='right'] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^='left'] {
  margin-right: 5px;
}

.tooltip[x-placement^='left'] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: var(--popover-background-color);
  color: var(--popover-text-color);
  border-radius: var(--box-radius);
  box-shadow: 0 5px 30px rgba(black, 0.1);
  max-width: 500px;
}

.tooltip.popover .popover-inner header {
  font-size: var(--font-s);
  font-weight: 500;
  padding: var(--spacing-xs) var(--spacing-m);
}

.tooltip.popover .popover-inner main {
  font-size: var(--font-s);
  padding: var(--spacing-xs) var(--spacing-m);
}

.tooltip.popover .popover-inner main li {
  padding: var(--spacing-s);
  margin-left: var(--spacing-m);
}

.tooltip.popover .popover-inner main ~ main {
  border-top: 1px solid var(--popover-hairline-color);
}

.tooltip.popover .popover-arrow {
  border-color: var(--popover-background-color);
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

/* Mapbox */

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-attrib {
  display: none !important;
}

/* V-Popover */

.button,
.alert,
.v-select {
  & .v-popover {
    & .trigger {
      display: flex !important;
    }
  }
}

/* Datepicker */

.mx-input {
  &:hover,
  &:focus {
    border-color: black !important;
  }
}

.mx-btn {
  &:hover {
    color: black !important;
    border-color: black !important;
  }
}

.mx-calendar-content {
  & .cell:hover {
    background-color: #ddd !important;
  }

  & .cell.active {
    background-color: black !important;
    color: white !important;

    &:hover {
      background-color: black !important;
    }
  }

  & .cell.in-range,
  & .cell.hover-in-range {
    background-color: #ededef !important;

    &:hover {
      background-color: #ddd !important;
    }
  }
}

.mx-table-date {
  & .today {
    color: black !important;
  }

  & .cell.not-current-month {
    background-color: white !important;
  }
}

.mx-time-column {
  & .mx-time-item {
    &.active {
      background-color: black !important;
      color: white !important;

      &:hover {
        background-color: black !important;
      }
    }

    &:hover {
      background-color: #ededef !important;
    }
  }
}
</style>
